<template>
  <v-container class="my-4 py-6">
    <h2>Descuentos:</h2>
    <v-row
      justify="center"
      class="px-2 mx-4 my-6"
    >
      <v-card
        class="px-4 py-4"
        max-width="800"
      >
        <p class="text-primary">
          Agregar Nuevo Descuento:
        </p>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation 
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="nuevoDescuento.nombre_descuento"
                label="Nombre del descuento"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols=12
              md="4"
            >
              <v-text-field
                v-model="nuevoDescuento.cantidad_compra_minima"
                type="number"
                label="Cantidad de compra mínima"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols=12
              md="4"
            >
              <v-text-field
                v-model.number="nuevoDescuento.porcentaje_descuento"
                type="number"
                label="Porcentaje de descuento"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="nuevoDescuento.tipo_descuento"
                :rules="[rules.required]"
                label="Tipo de descuento"
                :items="tipoDescuento"
                item-text="dato"
                item-value="id"
                dense
                solo
              ></v-select>
            </v-col>
            <v-col
              cols="12"
            >
              <v-select
                v-model="nuevoDescuento.productos"
                :rules="[rules.required]"
                :items="products"
                :menu-props="{ maxHeight: '400' }"
                item-text="producto.nombre_producto"
                item-value="producto.id"
                label="Productos:"
                multiple
                hint="Productos que tendrán descuento"
                persistent-hint
              >
                <template #append-item>
                  <div v-intersect="endIntersect"></div>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="nuevoDescuento.fecha_inicio"
                    :menu-props="{ maxHeight: '400' }"
                    label="Fecha de inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="nuevoDescuento.fecha_inicio"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-menu
                ref="menuFin"
                v-model="menuFin"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="nuevoDescuento.fecha_fin"
                    :menu-props="{ maxHeight: '400' }"
                    label="Fecha de finalización"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker2"
                  v-model="nuevoDescuento.fecha_fin"
                  @input="menuFin = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                :disabled="!valid"
                color="primary"
                @click="enviarDescuento"
              >
                {{ buttonAction }}
              </v-btn>
              <v-btn
                color="primary"
                @click="cleanForm"
              >
                limpiar campos 
              </v-btn>
            </v-card-actions>
          </v-row> 
        </v-form>
      </v-card>
    </v-row>

    <v-row
      class="my-4"
      justify="center"
    >
      <v-card
        v-for="descuento in todosLosDescuentos"
        :key="descuento.id"
        max-width="300"
        class="mx-4 my-4"
      >
        <v-card-text>
          <div
          v-show="descuento.vigencia"
        >
          Vigente
        </div>
          <p class="display-1 text--primary">
            {{ descuento.nombre_descuento }}
          </p>
          <div>
            Descuento: {{ Math.floor(descuento.porcentaje_descuento*100) }}%<br>
            Compra mínima: {{ descuento.cantidad_compra_minima }}<br>
            Productos en promoción: {{ descuento.productos.length}}<br>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="primary" 
            @click="editarDescuento(descuento)"
          >
            Editar
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="eliminarDescuento(descuento.id)"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <descuentos-cliente />
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from '@/mixins/formsValidations.js'
import { ApiAuth } from '@/api_utils/axios-base'

export default {
  metaInfo: {
    title: 'Descuentos'
  },
  components: {
    DescuentosCliente: () => import('./DescuentosCliente')
  },
  mixins: [validationMixin],
  data() {
    return {
      nuevoDescuento: {
        nombre_descuento: "",
        tipo_descuento: null,
        distribuidor: null,
        productos: [],
        cantidad_compra_minima: 0,
        porcentaje_descuento: 0,
        fecha_inicio: new Date().toISOString().substr(0,10),
        fecha_fin: new Date().toISOString().substr(0,10)
      },
      valid: true,
      menu: false,
      menuFin: false,
      dates: [new Date().toISOString().substr(0,10)],
      tipoDescuento: [],
      todosLosDescuentos: [],
      edit: false,
      products: [],
      page: 1,
      nextPage: null
    }
  },
  computed: {
    ...mapState('usuario', ['userData']),
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    buttonAction() {
      return this.edit ? "Modificar" : "Agregar"
    },
  },
  methods: {
    ...mapActions('products', ['fetchProducts']),
    async obtenerDatos() {
      try {
        let datosImportantes = await Promise.all([
          this.traerDescuentos(),
          this.traerTipoDescuentos()
        ])
        let descuentos = datosImportantes[0].data
        let tipoDescuentos = datosImportantes[1].data

        this.tipoDescuento = tipoDescuentos
        this.todosLosDescuentos = descuentos
        const responseInventory = await this.getInventoryDistributor()
        this.nextPage = responseInventory.next
        this.products = responseInventory.results
      } catch(error) {
        console.error("error getting descuentos data", error)
      }
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    editarDescuento(descuento) {
      let { id, ...currentDescuento } = descuento
      this.currentDescuentoId = id
      this.nuevoDescuento = currentDescuento
      this.nuevoDescuento.porcentaje_descuento = Math.floor(this.nuevoDescuento.porcentaje_descuento * 100)
      this.nuevoDescuento.fecha_inicio = this.shortStringDate(this.nuevoDescuento.fecha_inicio)
      this.nuevoDescuento.fecha_fin = this.shortStringDate(this.nuevoDescuento.fecha_fin)
      this.edit = true
    },
    async eliminarDescuento(descuendoId) {
      try {
        let eliminarDescuendoResp = await ApiAuth.delete(`/api/descuentos/${descuendoId}/`)
        this.obtenerDatos()
      } catch(error) {
        console.error("error deleting descuento",error)
      }
    },
    cleanForm() {
      Object.keys(this.nuevoDescuento).forEach(k => this.nuevoDescuento[k]= "")
      this.edit = false
    },
    async enviarDescuento() {
      if(!this.$refs.form.validate()) {
        return 
      }
      try {
        if(this.edit) {
          this.$store.commit('setLoading', true)
          this.nuevoDescuento.porcentaje_descuento = this.nuevoDescuento.porcentaje_descuento / 100
          this.nuevoDescuento.fecha_inicio = this.completeStringDate(this.nuevoDescuento.fecha_inicio, "T00:00")
          this.nuevoDescuento.fecha_fin = this.completeStringDate(this.nuevoDescuento.fecha_fin, "T23:59")
          // console.log("mod antes de enviar", this.nuevoDescuento)
          let modificarDescuento = await ApiAuth.put(`/api/descuentos/${this.currentDescuentoId}/`,
            this.nuevoDescuento
          )
          this.obtenerDatos()
          setTimeout(() => {this.$store.commit('setLoading', false)}, 500)
          // this.cleanForm()
          this.fetchProducts()
        } else {
          this.$store.commit('setLoading', true)
          //this.nuevoDescuento.fecha_inicio = this.dates[0]
          //this.nuevoDescuento.fecha_fin = this.dates[1]
          this.nuevoDescuento.distribuidor = this.userData.distribuidorId
          this.nuevoDescuento.porcentaje_descuento = this.nuevoDescuento.porcentaje_descuento / 100

          let crearDescuento = await ApiAuth.post('/crear-descuento', this.nuevoDescuento)
          setTimeout(() => {
            this.$store.commit('setLoading', false)
            this.fetchProducts()
          }, 500)
        }
        
      } catch(error) {
        console.error("error creating descuento", error)
      }
    },
    traerTipoDescuentos() {
      return ApiAuth.get('/api/tipo-descuentos/')
    },
    traerDescuentos() {
      return ApiAuth.post('/checkout/productos-con-descuento', {id_distribuidor: this.userData.distribuidorId} )
    },
    shortStringDate(date) {
      return new Date(date).toISOString().substr(0,10)
    },
    completeStringDate(date, sufix) {
      return date + sufix;
    }, 
    async getInventoryDistributor() {
      const inventory = await ApiAuth.get(`/inventarios/api/inventarios-sucursal/?id_sucursal=${this.userData.sucursalId}&page=${this.page}`) 
      const inventoryData = await inventory.data
      // this.products = inventoryData
      return inventoryData

    },
    async endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.page++
        if (this.nextPage !== null) {
          const responseInventory = await this.getInventoryDistributor()
          this.nextPage = responseInventory.next
          this.products = [...this.products, ...responseInventory.results]
        }
      }
    }
  },
  created() {
    this.obtenerDatos()
  }
}
</script>